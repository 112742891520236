<template>
  <b-row class="hp-authentication-page">
    <left-item />
    <b-col cols="12" lg="6" class="py-sm-64 py-lg-0">
      <b-row align-v="center" align-h="center" class="h-100 mx-4 mx-sm-n32">
        <b-col
          cols="12"
          md="9"
          xl="7"
          class="col-xxxl-5 px-8 px-sm-0 pt-24 pb-48"
        >
          <h2 class="mb-3 text-center">Iniciar Sesión con Google</h2>
          <div class="mx-auto text-center">
            <img
              :src="require('@/assets/img/app/logo/logo_fluxsolar.png')"
              alt
              width="50%"
              class="text-center"
            />
          </div>
          <div class="card-body pt-0">
            <h5 class="mb-4 text-center mt-5">Iniciando Sesión Espere..</h5>
          </div>
          <footer-item />
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import FooterItem from "./FooterItem.vue";
import { mapGetters, mapActions } from "vuex";
import LeftItem from "./LeftItem.vue";

export default {
  components: {
    LeftItem,
    FooterItem,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      processing: "auth/processing",
    }),
  },
  methods: {
    ...mapActions({ loginGoogle: "auth/loginGoogle" }),
    async sendLoginWithGoogle() {
      try {
        let self = this;
        const paramsLogin = this.$route.query;
        const resp = await this.loginGoogle(paramsLogin);
        if (resp.status == 200) {
          setTimeout(() => {
            self.$router.push({ name: "my-checklists-pending" });
          }, 1000);
        }
        if (resp.status == 401) {
          this.$bvToast.toast(
            "Este correo electrónico no se encuentra registrado, por favor intenta con otra cuenta",
            {
              title: `Error`,
              variant: "danger",
              solid: false,
            }
          );
          setTimeout(() => {
            self.$router.push({ name: "login" });
          }, 3000);
        }
      } catch (error) {}
    },
  },
  created() {
    this.sendLoginWithGoogle();
  },
};
</script>
